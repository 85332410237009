<!-- eslint-disable max-len -->
<template>
  <v-card class="pa-6">
    <p class="text-h4">Paramètres généraux</p>
    <v-row class="align-center">
      <v-col cols="3" v-if="!config.open">
        <v-btn @click="openForm()">Ouvrir le formulaire</v-btn>
      </v-col>
      <v-col cols="3" v-if="config.open">
        <v-btn @click="closeForm()" color="error">Fermer le formulaire</v-btn>
      </v-col>
      <v-col cols="3" v-if="!config.filterSaved">
        <v-btn @click="filterUsers()" color="error">Ceux qui ont sauvegardé et qui viennent seulement</v-btn>
      </v-col>
      <v-col cols="3" v-if="config.filterSaved">
        <v-btn @click="unFilterUsers()">Tous les utilisateurs</v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'ManagerPanel',
  components: {},
  props: {
    config: Object,
  },
  data() {
    return {};
  },
  methods: {
    openForm() {
      this.$emit('update-config', { open: true });
    },
    closeForm() {
      this.$emit('update-config', { open: false });
    },
    filterUsers() {
      this.$emit('update-config', { filterSaved: true });
    },
    unFilterUsers() {
      this.$emit('update-config', { filterSaved: false });
    },
  },
};
</script>
