<template>
  <v-container fluid class="fill-height pa-0">
    <v-row class="fill-height">
      <v-col lg="3" xl="2">
        <SidePanel>
          <v-card class="pa-5" outlined tile>
            <v-card-title class="justify-center"> Connexion avec </v-card-title>
            <v-card-actions class="justify-center">
              <v-btn href="/form"> Cascad ENSTA </v-btn>
            </v-card-actions>
          </v-card>
        </SidePanel>
      </v-col>
      <v-col lg="9" xl="10" class="back-panel">
        <BackPanel />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SidePanel from '../components/SidePanel.vue';
import BackPanel from '../components/BackPanel.vue';

export default {
  name: 'HomePage',
  components: {
    SidePanel,
    BackPanel,
  },
};
</script>

<style>
.back-panel {
  background-color: #0a406b;
}
</style>
