<template>
  <v-card class="mx-auto mt-4 mb-4" outlined max-width="200">
    <v-card-title class="align-end display-2"> {{ value }} </v-card-title>

    <v-card-text>
      <v-divider></v-divider>
      {{ name }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'NumberDisplay',
  props: {
    value: Number,
    name: String,
  },
};
</script>
