<!-- eslint-disable max-len -->
<template>
  <v-card class="pa-6">
    <p class="text-h4">
      {{ $t('form.guestQuestionsPanel.title') }} n°{{ humanIndex }} - {{ guest.firstName }} {{ guest.lastName }}
    </p>
    <QuestionsPanel :questions="questions" :answers="answers" @update-answer="updateAnswer" />
    <v-row>
      <v-col>
        <h6 class="subtitle-1">
          {{ $t('form.guestQuestionsPanel.delete') }}
        </h6>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="deleteGuest()" color="error">
          {{ $t('form.guestQuestionsPanel.deleteButton') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import QuestionsPanel from './QuestionsPanel.vue';

export default {
  name: 'GuestQuestionsPanel',
  components: {
    QuestionsPanel,
  },
  props: {
    guest: Object,
    index: String,
    questions: Object,
    answers: Object,
  },
  computed: {
    humanIndex() {
      return parseInt(this.index, 10) + 1;
    },
  },
  methods: {
    deleteGuest() {
      this.$emit('delete-guest', this.index);
    },
    updateAnswer(val) {
      this.$emit('update-answer', val);
    },
  },
};
</script>
