<!-- eslint-disable max-len -->
<template>
  <v-card class="pa-6">
    <p class="text-h4">Exporter les données</p>
    <v-row class="align-center">
      <v-col lg="4">
        <v-btn @click="downloadPictures()" :disabled="showProgress">
          Télécharger les images
        </v-btn>
      </v-col>
      <v-col lg="4">
        <v-btn @click="downloadResults()" :disabled="showProgress">
          Télécharger les résultats
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="showProgress">
      <v-col>
        <v-progress-linear v-if="!indeterminate" color="primary" striped rounded height="25" v-model="progress">
        </v-progress-linear>
        <v-progress-linear v-if="indeterminate" color="primary" indeterminate striped rounded height="25">
        </v-progress-linear>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'ExportPanel',
  components: {},
  data() {
    return {
      progress: 0,
      showProgress: false,
      indeterminate: false,
    };
  },
  methods: {
    downloadPictures() {
      return new Promise((resolve) => {
        this.showProgress = true;
        this.indeterminate = false;

        const socket = new WebSocket(process.env.VUE_APP_WS_URL);
        socket.onmessage = (event) => {
          this.progress = parseInt(event.data, 10);

          if (event.data === 'STOP') {
            socket.close();
            resolve();
          }
        };

        socket.onopen = () => {
          socket.send('compress-pictures');
        };
      })
        .then(() => {
          this.indeterminate = true;

          const url = `${process.env.VUE_APP_API_URL}/admin/pictures`;
          return this.axios.get(url, { responseType: 'blob' });
        })
        .then((res) => {
          const file = window.URL.createObjectURL(new Blob([res.data]));

          const docUrl = document.createElement('a');
          docUrl.href = file;
          docUrl.setAttribute('download', 'images.zip');
          document.body.appendChild(docUrl);
          docUrl.click();

          this.showProgress = false;
        });
    },
    downloadResults() {
      const url = `${process.env.VUE_APP_API_URL}/admin/results`;
      this.axios
        .get(url)
        .then((res) => {
          const file = window.URL.createObjectURL(new Blob([res.data]));

          const docUrl = document.createElement('a');
          docUrl.href = file;
          docUrl.setAttribute('download', 'results.csv');
          document.body.appendChild(docUrl);
          docUrl.click();
        });
    },
  },
};
</script>
