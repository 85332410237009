<template>
  <v-card class="pa-6">
    <p class="text-h2 mb-6">Formulaire d'inscription à la RDD</p>
    <p class="text-h5">Interface administrateur</p>
    <slot></slot>
  </v-card>
</template>

<script>
export default {
  name: 'TitleAdmin',
  components: {},
  props: {
    user: Object,
  },
};
</script>
