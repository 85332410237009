<!-- eslint-disable max-len -->
<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-card class="pa-6">
    <p class="text-h4">Liste des questions</p>
    <v-row class="align-center">
      <v-col>
        <v-data-table :headers="headers" :items="questions" class="elevation-1" :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc">
          <template v-slot:item.forUser="{ item }">
            <v-icon v-if="!item.forUser" color="green">mdi-check</v-icon>
            <v-icon v-if="item.forUser" color="red">mdi-close</v-icon>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon @click="deleteQuestion(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'QuestionTablePanel',
  components: {},
  props: {
    questions: Array,
  },
  data() {
    return {
      headers: [
        { text: 'Label', value: 'label' },
        { text: 'Question invité', value: 'forUser' },
        { text: 'Actions', value: 'actions' },
      ],
      sortBy: 'forUser',
      sortDesc: false,
    };
  },
  methods: {
    deleteQuestion({ index, forUser }) {
      this.$emit('delete-question', { index, forUser });
    },
  },
};
</script>
