<!-- eslint-disable max-len -->
<template>
  <v-card class="pa-6">
    <p class="text-h4">
      {{ $t('form.questionsPanel.title') }}
    </p>
    <QuestionsPanel :questions="questions" :answers="answers" @update-answer="updateAnswer" />
  </v-card>
</template>

<script>
import QuestionsPanel from './QuestionsPanel.vue';

export default {
  name: 'UserQuestionsPanel',
  components: {
    QuestionsPanel,
  },
  props: {
    questions: Object,
    answers: Object,
  },
  data() {
    return {
      newAnswers: { ...this.answers },
      radioGroup: 1,
    };
  },
  methods: {
    updateAnswer(val) {
      this.$emit('update-answer', val);
    },
  },
};
</script>
