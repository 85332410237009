<template>
  <v-dialog :value="value" persistent max-width="500">
    <v-card>
      <v-card-title class="text-h5">
        {{ $t('dialog.save.title') }}
      </v-card-title>
      <v-card-text>
        {{ $t('dialog.save.info') }}
      </v-card-text>
      <v-card-actions>
        <v-row class="mb-0">
          <v-col align="center">
            <v-btn @click="quit()">
              {{ $t('general.quit') }}
            </v-btn>
          </v-col>
          <v-col align="center">
            <v-btn color="primary" @click="update()">
              {{ $t('general.continue') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SaveDialog',
  props: {
    value: Boolean,
  },
  data() {
    return {
      dialog: this.value,
    };
  },
  methods: {
    quit() {
      window.location.replace('/');
    },
    update() {
      this.$emit('input', false);
    },
  },
};
</script>
