<template>
  <v-row class="ma-12 justify-center">
    <v-col>
      <v-img src="/image/logo-ensta.png"></v-img>
      <slot></slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'SidePanel',
};
</script>
