<template>
  <v-card class="pa-6">
    <p class="text-h4">Ajouter un administrateur</p>
    <v-row class="align-center">
      <v-col cols="12" md="4">
        <v-text-field v-model="username" label="Username" filled />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="addAdmin()" :disabled="!username">
          Ajouter
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'AddAdminPanel',
  components: {},
  data() {
    return {
      username: '',
    };
  },
  methods: {
    addAdmin() {
      this.$emit('add-admin', this.username);
      this.username = '';
    },
  },
};
</script>
