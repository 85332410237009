<template>
  <v-toolbar height="100px" class="topbar primary white--text">
    <div class="flex-grow-1"></div>
    <v-btn class="mr-6" @click="logout()">Déconnexion</v-btn>
  </v-toolbar>
</template>

<script>
export default {
  name: 'TopBar',
  components: {},
  methods: {
    logout() {
      const url = `${process.env.VUE_APP_API_URL}/logout`;
      this.axios
        .delete(url)
        .then((response) => {
          if (response.status === 200) {
            window.location.replace('/');
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    },
  },
};
</script>

<style>
.topbar {
  background-color: #003b71;
  padding: 0;
  background-image: url("../assets/bandeau.jpg");
  background-position: center;
  background-size: cover;
}
</style>
