<!-- eslint-disable max-len -->
<template>
  <v-card class="pa-6">
    <p class="text-h4">Photos pour le diaporama</p>
    <v-row class="align-center">
      <v-col>
        <v-carousel>
          <v-carousel-item v-for="(picture, index) in pictures" :key="index" :src="picture" cycle>
            <v-btn absolute top color="red" class="my-10 mx-5" fab @click="deletePicture(index)" dark>
              <v-icon>mdi-delete-forever</v-icon>
            </v-btn>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'PicturesPanel',
  components: {},
  props: {
    pictures: Array,
  },
  methods: {
    deletePicture(index) {
      this.$emit('delete-picture', index);
    },
  },
};
</script>
