<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-card class="pa-6">
    <p class="text-h4">Liste des utilisateurs</p>
    <v-row class="align-center">
      <v-col cols="12">
        <v-data-table :headers="headers" :items="selectableUsers" class="elevation-1" show-select
          @toggle-select-all="selectAllToggle">
          <template v-slot:item.isComing="{ item }">
            <BooleanIcon :value="item.isComing" />
          </template>
          <template v-slot:item.hasSaved="{ item }">
            <BooleanIcon :value="item.hasSaved" />
          </template>
          <template v-slot:item.hasValidated="{ item }">
            <BooleanIcon :value="item.hasValidated" />
          </template>
          <template v-slot:item.data-table-select="{ item }">
            <v-simple-checkbox v-model="item.isSelected"></v-simple-checkbox>
          </template>
        </v-data-table>
      </v-col>
      <v-col>
        <v-btn @click="deleteUsers()" color="error">
          Supprimer
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import BooleanIcon from '../BooleanIcon.vue';

export default {
  name: 'UserTablePanel',
  components: {
    BooleanIcon,
  },
  props: {
    users: Array,
  },
  data() {
    return {
      headers: [
        { text: 'Prénom', value: 'firstName' },
        { text: 'Nom', value: 'lastName' },
        { text: 'Username cascad', value: 'username' },
        { text: 'Mail', value: 'personalMail' },
        { text: 'Vient', value: 'isComing' },
        { text: 'A sauvegardé', value: 'hasSaved' },
        { text: 'A validé', value: 'hasValidated' },
      ],
      selectableUsers: this.users.map((user) => ({ isSelected: false, ...user })),
    };
  },
  methods: {
    selectAllToggle({ value }) {
      this.selectableUsers = this.users.map((user) => ({ isSelected: value, ...user }));
    },
    deleteUsers() {
      const toDelete = this.selectableUsers
        .filter((user) => user.isSelected)
        .map((user) => user.username);
      this.$emit('delete-users', toDelete);
    },
  },
};
</script>
