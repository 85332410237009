<template>
  <v-card class="pa-6">
    <p class="text-h4">Ajouter un utilisateur</p>
    <v-row class="align-center">
      <v-col cols="12" md="4">
        <v-text-field v-model="username" label="Username cascad" filled />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="firstName" label="Prénom" filled />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="lastName" label="Nom" filled />
      </v-col>
      <v-col>
        <v-btn @click="addUser()" :disabled="!username || !firstName || !lastName">
          Ajouter
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'AddUserPanel',
  components: {},
  data() {
    return {
      username: '',
      firstName: '',
      lastName: '',
    };
  },
  methods: {
    addUser() {
      const newUser = {
        username: this.username,
        firstName: this.firstName,
        lastName: this.lastName,
      };
      this.$emit('add-user', newUser);
      this.username = '';
      this.firstName = '';
      this.lastName = '';
    },
  },
};
</script>
