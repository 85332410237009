<!-- eslint-disable max-len -->
<template>
  <div>
    <v-row justify="center" v-for="(question, index) in questions" :key="index">
      <v-col>
        <h6 class="subtitle-1">
          {{ question.label }}
        </h6>
        <v-radio-group @change="value => updateAnswer(index, value)" v-model="newAnswers[index]" :rules="radioRules">
          <v-radio key="true" :label="$t('general.yes')" :value="true"></v-radio>
          <v-radio key="false" :label="$t('general.no')" :value="false"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'QuestionsPanel',
  components: {
  },
  props: {
    questions: Object,
    answers: Object,
  },
  data() {
    return {
      newAnswers: { ...this.answers },
      radioGroup: 1,
      radioRules: [
        (v) => v !== undefined || this.$i18n.t('errors.radioRequired'),
      ],
    };
  },
  methods: {
    updateAnswer(index, value) {
      this.$emit('update-answer', { index, value });
    },
  },
};
</script>
