<template>
  <v-card class="pa-6">
    <p class="text-h4">Ajouter une liste d'utilisateurs</p>
    <v-row class="align-center">
      <v-col cols="12" md="4">
        <v-file-input accept=".csv" label="Liste d'utilisateurs" v-model="file"></v-file-input>
      </v-col>
      <v-col>
        <v-btn @click="addUsers()" :disabled="!file">
          Ajouter
        </v-btn>
      </v-col>
      <v-col>
        <v-btn @click="downloadExample()">
          Exemple de fichier CSV
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'AddUsersPanel',
  components: {},
  data() {
    return {
      file: null,
    };
  },
  methods: {
    addUsers() {
      this.$emit('add-users', this.file);
      this.file = null;
    },
    downloadExample() {
      const url = `${process.env.VUE_APP_API_URL}/admin/example/csv-users`;
      this.axios
        .get(url, { responseType: 'blob' })
        .then((res) => {
          const file = window.URL.createObjectURL(new Blob([res.data]));

          const docUrl = document.createElement('a');
          docUrl.href = file;
          docUrl.setAttribute('download', 'users-example.csv');
          document.body.appendChild(docUrl);
          docUrl.click();
        });
    },
  },
};
</script>
