<template>
  <div>
    <v-icon v-if="value === true" color="green">mdi-check</v-icon>
    <v-icon v-if="value === false" color="red">mdi-close</v-icon>
    <v-icon v-if="value === null">mdi-help-circle</v-icon>
  </div>
</template>

<script>
export default {
  name: 'BooleanIcon',
  components: {},
  props: {
    value: Boolean,
  },
};
</script>
