<!-- eslint-disable max-len -->
<template>
  <v-container fluid class="pt-0 fill-height grey lighten-4">
    <v-row class="fill-height">
      <v-col>
        <TopBar />
        <v-row class="justify-center">
          <v-col cols="12" md="8" class="my-6">
            <TitleAdmin>
              <v-tabs fixed-tabs v-model="tab" :vertical="$vuetify.breakpoint.mobile">
                <v-tab v-for="item in tabs" :key="item.key">
                  {{ item.name }}
                </v-tab>
              </v-tabs>
            </TitleAdmin>
          </v-col>
          <v-col cols="12" md="8">
            <v-tabs-items v-model="tab" class="grey lighten-4">
              <v-tab-item key="main">
                <v-row justify="center">
                  <v-col cols="12">
                    <ConnectAsPanel />
                  </v-col>
                  <v-col cols="12">
                    <ManagerPanel v-if="render.config" :config="config" @update-config="updateConfig" />
                  </v-col>
                  <v-col cols="12">
                    <GuestNumberPanel v-if="render.config" :config="config" @update-config="updateConfig" />
                  </v-col>
                  <v-col cols="12">
                    <PictureNumberPanel v-if="render.config" :config="config" @update-config="updateConfig" />
                  </v-col>
                  <v-col cols="12">
                    <SeatNumberPanel v-if="render.config" :config="config" @update-config="updateConfig" />
                  </v-col>
                  <v-col cols="12">
                    <StatsPanel :stats="stats" />
                  </v-col>
                  <v-col cols="12">
                    <ExportPanel />
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item key="user">
                <v-row justify="center">
                  <v-col cols="12">
                    <AddUserPanel @add-user="addUser" />
                  </v-col>
                  <v-col cols="12">
                    <AddUsersPanel @add-users="addUsers" />
                  </v-col>
                  <v-col cols="12" v-if="render.users">
                    <UserTablePanel :users="users" @delete-users="deleteUsers" />
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item key="question">
                <v-row justify="center">
                  <v-col cols="12">
                    <AddQuestionPanel @add-question="addQuestion" />
                  </v-col>
                  <v-col cols="12" v-if="render.questions">
                    <QuestionTablePanel :questions="questions" @delete-question="deleteQuestion" />
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item key="admin">
                <v-row justify="center">
                  <v-col cols="12">
                    <AddAdminPanel @add-admin="addAdmin" />
                  </v-col>
                  <v-col cols="12" v-if="render.admins">
                    <AdminTablePanel :admins="admins" @delete-admin="deleteAdmin" />
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import TopBar from '../components/TopBar.vue';
import TitleAdmin from '../components/admin/TitleAdmin.vue';
import GuestNumberPanel from '../components/admin/GuestNumberPanel.vue';
import PictureNumberPanel from '../components/admin/PictureNumberPanel.vue';
import SeatNumberPanel from '../components/admin/SeatNumberPanel.vue';
import ManagerPanel from '../components/admin/ManagerPanel.vue';
import StatsPanel from '../components/admin/StatsPanel.vue';
import ExportPanel from '../components/admin/ExportPanel.vue';
import AddAdminPanel from '../components/admin/AddAdminPanel.vue';
import AdminTablePanel from '../components/admin/AdminTablePanel.vue';
import AddUserPanel from '../components/admin/AddUserPanel.vue';
import AddUsersPanel from '../components/admin/AddUsersPanel.vue';
import UserTablePanel from '../components/admin/UserTablePanel.vue';
import AddQuestionPanel from '../components/admin/AddQuestionPanel.vue';
import QuestionTablePanel from '../components/admin/QuestionTablePanel.vue';
import ConnectAsPanel from '../components/admin/ConnectAsPanel.vue';

export default {
  name: 'AdminPage',
  components: {
    TopBar,
    TitleAdmin,
    GuestNumberPanel,
    PictureNumberPanel,
    SeatNumberPanel,
    ManagerPanel,
    StatsPanel,
    ExportPanel,
    AddAdminPanel,
    AdminTablePanel,
    AddUserPanel,
    AddUsersPanel,
    UserTablePanel,
    AddQuestionPanel,
    QuestionTablePanel,
    ConnectAsPanel,
  },
  data() {
    return {
      users: [],
      admins: [],
      questions: [],
      stats: [],
      config: null,
      render: {
        users: false,
        admins: false,
        questions: false,
        stats: false,
        config: false,
      },
      tab: 'main',
      tabs: [
        { name: 'Général', key: 'main' },
        { name: 'Utilisateurs', key: 'user' },
        { name: 'Questions', key: 'question' },
        { name: 'Administrateurs', key: 'admin' },
      ],
    };
  },
  computed: {
    overlay() {
      return !(
        this.render.users
        && this.render.admins
        && this.render.questions
        && this.render.stats
        && this.render.config
      );
    },
  },
  created() {
    this.getUsers();
    this.getAdmins();
    this.getQuestions();
    this.getStats();
    this.getConfig();
  },
  methods: {
    getUsers() {
      const url = `${process.env.VUE_APP_API_URL}/admin/users`;
      return this.axios
        .get(url)
        .then((response) => {
          this.users = response.data;
          this.render.users = true;
        });
    },
    getAdmins() {
      const url = `${process.env.VUE_APP_API_URL}/admin/admins`;
      return this.axios
        .get(url)
        .then((response) => {
          this.admins = response.data;
          this.render.admins = true;
        });
    },
    getQuestions() {
      const url = `${process.env.VUE_APP_API_URL}/admin/questions`;
      return this.axios
        .get(url)
        .then((response) => {
          this.questions = response.data;
          this.render.questions = true;
        });
    },
    getStats() {
      const url = `${process.env.VUE_APP_API_URL}/admin/stats`;
      return this.axios
        .get(url)
        .then((response) => {
          this.stats = response.data;
          this.render.stats = true;
        });
    },
    getConfig() {
      const url = `${process.env.VUE_APP_API_URL}/admin/config`;
      return this.axios
        .get(url)
        .then((response) => {
          this.config = response.data;
          this.render.config = true;
        });
    },
    addAdmin(username) {
      this.render.admins = false;

      const url = `${process.env.VUE_APP_API_URL}/admin/admin`;
      const newAdmin = { username };
      this.axios
        .post(url, newAdmin)
        .then(() => {
          this.getAdmins();
        });
    },
    addUser(user) {
      this.render.users = false;

      const url = `${process.env.VUE_APP_API_URL}/admin/user`;
      const newUser = { cascadMail: '', ...user };
      this.axios
        .post(url, newUser)
        .then(() => {
          this.getUsers();
          this.getStats();
        });
    },
    addUsers(file) {
      this.render.users = false;

      const formData = new FormData();
      formData.append('file', file);

      const url = `${process.env.VUE_APP_API_URL}/admin/users`;
      const headers = {
        'Content-Type': 'multipart/form-data',
      };

      this.axios
        .post(url, formData, { headers })
        .then(() => {
          this.getUsers();
          this.getStats();
        });
    },
    addQuestion(question) {
      this.render.questions = false;

      const url = `${process.env.VUE_APP_API_URL}/admin/question`;
      this.axios
        .post(url, question)
        .then(() => {
          this.getQuestions();
        });
    },
    deleteUsers(usernames) {
      this.render.users = false;

      const url = `${process.env.VUE_APP_API_URL}/admin/delete-users`;
      this.axios
        .post(url, usernames)
        .then(() => {
          this.getUsers();
          this.getStats();
        });
    },
    deleteAdmin(username) {
      this.render.admins = false;

      const url = `${process.env.VUE_APP_API_URL}/admin/admin/${username}`;
      this.axios
        .delete(url)
        .then(() => {
          this.getAdmins();
        });
    },
    deleteQuestion({ index, forUser }) {
      this.render.questions = false;

      const url = forUser
        ? `${process.env.VUE_APP_API_URL}/admin/question/user/${index}`
        : `${process.env.VUE_APP_API_URL}/admin/question/guest/${index}`;

      this.axios
        .delete(url)
        .then(() => {
          this.getQuestions();
        });
    },
    updateConfig(newConfig) {
      const url = `${process.env.VUE_APP_API_URL}/admin/config`;
      return this.axios
        .post(url, { ...this.config, ...newConfig })
        .then(() => {
          this.getConfig();
        });
    },
  },
};
</script>
