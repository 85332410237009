<!-- eslint-disable max-len -->
<template>
  <v-container fluid class="pt-0 fill-height grey lighten-4">
    <v-row class="fill-height">
      <v-col>
        <TopBar />
        <v-row class="justify-center">
          <v-col cols="12" md="8" class="my-6">
            <v-row class="mb-3">
              <v-col>
                <TitleUser :firstName="user.firstName" :lastName="user.lastName" />
              </v-col>
            </v-row>
            <v-form v-if="(!viewQRCodes)" ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col
                  v-if="render.pictures && user.isComing && (Object.keys(this.pictures).length < config.picturesLimit)">
                  <AddPicturesPanel :picturesCount="pictures.length" @add-picture="addPicture" />
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="render.pictures && user.isComing && pictures.length">
                  <PicturesPanel :pictures="pictures" @delete-picture="deletePicture" />
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="render.user">
                  <UserInfoPanel :personalMail="user.personalMail" :isComing="user.isComing"
                    :acceptAlumni="user.acceptAlumni" @update-personnal-email="updatePersonnalMail"
                    @update-is-coming="updateIsComing" @update-accept-alumni="updateAcceptAlumni" />
                </v-col>
              </v-row>
              <v-row v-if="Object.keys(userQuestions).length">
                <v-col v-if="render.user && user.isComing">
                  <UserQuestionsPanel :questions="userQuestions" :answers="userAnswers"
                    @update-answer="({ index, value }) => updateUserAnswer(index, value)" />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <GuestAddPanel
                    v-if="render.guests && user.isComing && (Object.keys(this.guests).length < config.guestsLimit) && !isRoomFull"
                    @add-guest="addGuest" />
                </v-col>
              </v-row>
              <v-row v-for="(guest, index) in guests" :key="index">
                <v-col v-if="render.guests && user.isComing">
                  <GuestQuestionsPanel :guest="guest" :index="index" @delete-guest="deleteGuest"
                    :questions="guestQuestions" :answers="guestAnswers[index]"
                    @update-answer="({ index: qIndex, value }) => updateGuestAnswer(index, qIndex, value)" />
                </v-col>
              </v-row>
              <v-row class="mb-3">
                <v-col>
                  <SavePanel :valid="valid" @reset="reset" @save="save" @validate="validate" />
                </v-col>
              </v-row>
            </v-form>
            <div v-if="(viewQRCodes && user.hasValidated && !!qrcodes.user)">
              <v-row class="mb-3">
                <v-col cols="12" md="6">
                  <QRCodePanel :src="qrcodes.user" :firstName="user.firstName" :lastName="user.lastName" />
                </v-col>
                <v-col cols="12" md="6" v-for="(qrcode, index) in qrcodes.guests" :key="index">
                  <QRCodePanel :src="qrcode" :firstName="guests[index].firstName" :lastName="guests[index].lastName" />
                </v-col>
              </v-row>
            </div>
            <v-row v-if="(user.hasValidated && !!qrcodes.user)" class="mb-3">
              <v-col>
                <SwitchPanel :viewQRCodes="viewQRCodes" @update-view-qr-codes="updateViewQRCodes" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <SaveDialog v-model="dialog.save" />
    <ValidateDialog v-model="dialog.validate" :body="user.isComing" />
  </v-container>
</template>

<script>
import TopBar from '../components/TopBar.vue';
import TitleUser from '../components/user/TitleUser.vue';
import UserInfoPanel from '../components/user/UserInfoPanel.vue';
import AddPicturesPanel from '../components/user/AddPicturesPanel.vue';
import PicturesPanel from '../components/user/PicturesPanel.vue';
import UserQuestionsPanel from '../components/user/UserQuestionsPanel.vue';
import SavePanel from '../components/user/SavePanel.vue';
import GuestAddPanel from '../components/user/GuestAddPanel.vue';
import GuestQuestionsPanel from '../components/user/GuestQuestionsPanel.vue';
import QRCodePanel from '../components/QRCodePanel.vue';
import SaveDialog from '../components/user/SaveDialog.vue';
import ValidateDialog from '../components/user/ValidateDialog.vue';
import SwitchPanel from '../components/user/SwitchPanel.vue';

export default {
  name: 'FormPage',
  components: {
    TopBar,
    TitleUser,
    UserInfoPanel,
    AddPicturesPanel,
    PicturesPanel,
    UserQuestionsPanel,
    SavePanel,
    GuestAddPanel,
    GuestQuestionsPanel,
    QRCodePanel,
    SaveDialog,
    ValidateDialog,
    SwitchPanel,
  },
  data() {
    return {
      user: {
        cascadMail: '',
        firstName: '',
        lastName: '',
        personalMail: '',
        username: '',
        isComing: true,
        hasValidated: false,
        acceptAlumni: true,
      },
      userQuestions: {},
      guestQuestions: {},
      userAnswers: {},
      guestAnswers: {},
      guests: {},
      pictures: [],
      config: null,
      isRoomFull: true,
      render: {
        questions: false,
        user: false,
        guests: false,
        pictures: false,
        config: false,
      },
      valid: true,
      dialog: {
        save: false,
        validate: false,
      },
      qrcodes: {
        user: null,
        guests: [],
      },
      viewQRCodes: false,
    };
  },
  computed: {
    overlay() {
      return !Object
        .values(this.render)
        .every((val) => val);
    },
  },
  created() {
    this
      .getConfig()
      .then(() => this.getIsRoomFull())
      .then(() => this.getQuestions())
      .then(() => {
        const promises = [this.getUser(), this.getGuests(), this.getPictures()];
        return Promise
          .all(promises);
      });
  },
  methods: {
    listToObjectDict(array) {
      return array.reduce((result, { index, ...rest }) => {
        // eslint-disable-next-line no-param-reassign
        result[index] = rest;
        return result;
      }, {});
    },
    listToSingleValueDict(array) {
      return array.reduce((result, { index, value }) => {
        // eslint-disable-next-line no-param-reassign
        result[index] = value;
        return result;
      }, {});
    },
    getConfig() {
      const url = `${process.env.VUE_APP_API_URL}/api/config`;
      return this.axios
        .get(url)
        .then((response) => {
          this.config = response.data;
          this.render.config = true;
        });
    },
    getIsRoomFull() {
      const url = `${process.env.VUE_APP_API_URL}/api/is-room-full`;
      return this.axios
        .get(url)
        .then((response) => {
          this.isRoomFull = response.data;
        });
    },
    getQuestions() {
      this.render.questions = false;

      const url = `${process.env.VUE_APP_API_URL}/api/questions`;
      return this.axios
        .get(url)
        .then((response) => {
          this.userQuestions = this.listToObjectDict(
            response.data
              .filter(({ forUser }) => forUser),
          );

          this.guestQuestions = this.listToObjectDict(
            response.data
              .filter(({ forUser }) => !forUser),
          );

          this.render.questions = true;
        });
    },
    getUser() {
      this.render.user = false;

      const url = `${process.env.VUE_APP_API_URL}/api/user`;
      return this.axios
        .get(url)
        .then((response) => {
          this.user = response.data;
          if (this.user.isComing === null) {
            this.user.isComing = true;
          }
          if (this.user.acceptAlumni === null) {
            this.user.acceptAlumni = true;
          }
          if (this.user.hasValidated) {
            this.viewQRCodes = true;
          }

          return this.getUserAnswers();
        })
        .then(() => {
          if (this.user.hasValidated && this.user.isComing) {
            return this.getQRCodes();
          }
          return null;
        })
        .then(() => {
          this.render.user = true;
        });
    },
    getUserAnswers() {
      const url = `${process.env.VUE_APP_API_URL}/api/user/answers`;
      return this.axios
        .get(url)
        .then((userResponse) => {
          this.userAnswers = this.listToSingleValueDict(
            userResponse.data
              .filter(({ index: qIndex }) => (
                this.userQuestions[qIndex] !== undefined
              )),
          );
        });
    },
    getGuests() {
      this.render.guests = false;

      const url = `${process.env.VUE_APP_API_URL}/api/guests`;
      return this.axios
        .get(url)
        .then((response) => {
          this.guests = this.listToObjectDict(response.data);
          return this.getGuestAnswers();
        })
        .then(() => {
          this.render.guests = true;
        });
    },
    getGuestAnswers() {
      const promises = Object
        .keys(this.guests)
        .map((index) => {
          this.guestAnswers[index] = {};

          const guestUrl = `${process.env.VUE_APP_API_URL}/api/guest/answers/${index}`;
          return this.axios
            .get(guestUrl)
            .then((guestResponse) => {
              this.guestAnswers[index] = this.listToSingleValueDict(
                guestResponse.data
                  .filter(({ index: qIndex }) => (
                    this.guestQuestions[qIndex] !== undefined
                  )),
              );
            });
        });
      return Promise.all(promises);
    },
    getPictures() {
      this.render.pictures = false;

      const url = `${process.env.VUE_APP_API_URL}/api/pictures`;
      this.axios
        .get(url)
        .then((res) => {
          this.pictures = res.data;
          this.render.pictures = true;
        });
    },
    getQRCodes() {
      const url = `${process.env.VUE_APP_API_URL}/api/qrcode`;
      this.axios
        .get(url)
        .then((res) => {
          this.qrcodes.user = res.data.user;
          this.qrcodes.guests = res.data.guests;
        });
    },
    updatePersonnalMail(newVal) {
      this.user.personalMail = newVal;
    },
    updateIsComing(newVal) {
      this.user.isComing = newVal;
    },
    updateAcceptAlumni(newVal) {
      this.user.acceptAlumni = newVal;
    },
    addPicture(file) {
      const formData = new FormData();
      formData.append('file', file);

      const url = `${process.env.VUE_APP_API_URL}/api/picture`;
      const headers = {
        'Content-Type': 'multipart/form-data',
      };

      this.axios
        .post(url, formData, { headers })
        .then(() => {
          this.getPictures();
        });
    },
    deletePicture(index) {
      const url = `${process.env.VUE_APP_API_URL}/api/picture/${index}`;
      this.axios
        .delete(url)
        .then(() => this.getPictures());
    },
    updateUserAnswer(index, value) {
      this.userAnswers[index] = value;
    },
    updateGuestAnswer(index, qIndex, value) {
      if (this.guestAnswers[index] === undefined) {
        this.guestAnswers[index] = {};
      }
      this.guestAnswers[index][qIndex] = value;
    },
    addGuest(guest) {
      const url = `${process.env.VUE_APP_API_URL}/api/guest`;
      this.axios
        .post(url, guest)
        .then(() => this.getGuests())
        .then(() => this.getIsRoomFull());
    },
    deleteGuest(index) {
      const url = `${process.env.VUE_APP_API_URL}/api/guest/${index}`;
      this.axios
        .delete(url)
        .then(() => this.getGuests());
    },
    reset() {
      const url = `${process.env.VUE_APP_API_URL}/api/user`;
      this.axios
        .delete(url)
        .then(() => this.getUser())
        .then(() => this.getGuests());
    },
    updateUser() {
      const userUrl = `${process.env.VUE_APP_API_URL}/api/user`;
      return this.axios
        .post(userUrl, this.user)
        .then(() => {
          const answersToSend = Object
            .entries(this.userAnswers)
            .map(([index, value]) => ({ index: parseInt(index, 10), value }));

          const answersUrl = `${process.env.VUE_APP_API_URL}/api/user/answers`;
          return this.axios.post(answersUrl, answersToSend);
        })
        .then(() => {
          const promises = Object
            .keys(this.guests)
            .map((index) => {
              const answersToSend = Object
                .entries(this.guestAnswers[index])
                .map(([qIndex, value]) => ({ index: parseInt(qIndex, 10), value }));

              const answersUrl = `${process.env.VUE_APP_API_URL}/api/guest/answers/${index}`;
              return this.axios.post(answersUrl, answersToSend);
            });

          return Promise.all(promises);
        })
        .then(() => this.getUser())
        .then(() => this.getGuests());
    },
    save() {
      return this.updateUser()
        .then(() => {
          this.dialog.save = true;
        });
    },
    validate() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.user.hasValidated = true;
        return this.updateUser()
          .then(() => {
            this.dialog.validate = true;
          });
      }
      return null;
    },
    updateViewQRCodes(newViewQRCodess) {
      this.viewQRCodes = newViewQRCodess;
    },
  },
};
</script>
