<template>
  <v-row class="back-panel fill-height align-center justify-center">
    <v-col lg="8" xl="4" class="justify-center align-center">
      <v-row>
        <v-img src="/image/logo-rdd.jpg"></v-img>
      </v-row>
      <v-row class="mt-12 white-text" justify="center">
        <slot></slot>
        <h3 class="title">Pour toute information sur le Gala : https://gala.data-ensta.fr/</h3>
        <h2 class="mt-6">
          Pour toute question veuillez nous contacter par mail à l'adresse
          suivante
        </h2>
        <h3 class="title">rdd.ensta@gmail.com</h3>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'BackPanel',
};
</script>

<style>
.white-text {
  color: white
}
</style>
