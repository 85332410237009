<template>
  <v-card class="pa-6">
    <p class="text-h4">Se connecter en tant qu'un utilisateur</p>
    <v-row class="align-center">
      <v-col cols="12" md="4">
        <v-text-field v-model="username" label="Username" filled />
      </v-col>
      <v-col>
        <v-btn @click="connectAs()" :disabled="!username">
          Connexion
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'ConnectAsPanel',
  components: {},
  data() {
    return {
      username: '',
    };
  },
  methods: {
    connectAs() {
      const url = `${process.env.VUE_APP_API_URL}/admin/connect-as/${this.username}`;
      this.axios
        .get(url)
        .then(() => {
          window.location.replace('/form');
        });
    },
  },
};
</script>
