<!-- eslint-disable max-len -->
<template>
  <v-card class="pa-6">
    <p class="text-h4">Ajouter des photos pour le diaporama</p>
    <v-row class="align-center">
      <v-col cols="12" md="4">
        <v-file-input accept="image/*" label="Photos" show-size v-model="file"></v-file-input>
      </v-col>
      <v-col>
        <v-btn @click="addPicture()" :disabled="!file">
          Ajouter
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'AddPicturesPanel',
  components: {},
  props: {
    picturesCount: Number,
  },
  data() {
    return {
      file: null,
    };
  },
  methods: {
    addPicture() {
      this.$emit('add-picture', this.file);
      this.file = null;
    },
  },
};
</script>
