<!-- eslint-disable max-len -->
<template>
  <v-card class="pa-6">
    <p class="text-h4">Ajouter une question</p>
    <v-row class="align-center">
      <v-col cols="12" md="6">
        <v-text-field v-model="label" label="Question" filled />
      </v-col>
      <v-col>
        <v-checkbox v-model="notForUser" label="Question pour les invités" :value="true"></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="addQuestion()" :disabled="!label">
          Ajouter
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'AddQuestionPanel',
  components: {},
  data() {
    return {
      label: '',
      notForUser: false,
      isBool: true,
      values: [],
    };
  },
  computed: {
    forUser() {
      return !this.notForUser;
    },
  },
  methods: {
    addQuestion() {
      const question = {
        label: this.label,
        forUser: this.forUser,
        isBool: this.isBool,
        values: this.values,
      };
      this.$emit('add-question', question);
      this.label = '';
    },
  },
};
</script>
