<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-card class="pa-6">
    <p class="text-h4">Liste des administrateurs</p>
    <v-row class="align-center">
      <v-col cols="12" md="6">
        <v-data-table :headers="headers" :items="admins" class="elevation-1">
          <template v-slot:item.actions="{ item }">
            <v-icon @click="deleteAdmin(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'AdminTablePanel',
  components: {},
  props: {
    admins: Array,
  },
  data() {
    return {
      headers: [
        { text: 'Username cascad', value: 'username' }, { text: 'Actions', value: 'actions' }],
    };
  },
  methods: {
    deleteAdmin({ username }) {
      this.$emit('delete-admin', username);
    },
  },
};
</script>
