import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from 'axios';

import HomePage from './pages/HomePage.vue';
import ClosePage from './pages/ClosePage.vue';
import ForbiddenPage from './pages/ForbiddenPage.vue';
import FormPage from './pages/FormPage.vue';
import AdminPage from './pages/AdminPage.vue';
import EndPage from './pages/EndPage.vue';

Vue.use(VueRouter);

function loginCheck(endpoint, to, next) {
  const apiUrl = `${process.env.VUE_APP_API_URL}/${endpoint}`;
  axios
    .get(apiUrl)
    .then(() => {
      next();
    }).catch((err) => {
      if (err.response && err.response.status === 503) {
        next('/close');
        return;
      }

      if (!err.response || err.response.status !== 401) {
        next('/forbidden');
        return;
      }

      const nextUrl = `${window.location.origin}${to.path}`;
      window.location.replace(
        `${process.env.VUE_APP_API_URL}/login/?next=${nextUrl}`,
      );
    });
}

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: HomePage,
      name: 'HomePage',
    },
    {
      path: '/close',
      component: ClosePage,
      name: 'ClosePage',
    },
    {
      path: '/forbidden',
      component: ForbiddenPage,
      name: 'ForbiddenPage',
    },
    {
      path: '/end',
      component: EndPage,
      name: 'EndPage',
    },
    {
      path: '/form',
      component: FormPage,
      name: 'FormPage',
      beforeEnter(to, from, next) {
        loginCheck('api', to, next);
      },
    },
    {
      path: '/admin/',
      component: AdminPage,
      name: 'AdminPage',
      beforeEnter(to, from, next) {
        loginCheck('admin', to, next);
      },
    },
  ],
});

export default router;
