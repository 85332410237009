<template>
  <v-card class="pa-6 text-center">
    <p class="text-h4 ">{{ firstName }} {{ lastName }}</p>
    <v-row class="justify-center">
      <v-col cols="12" md="6" class="my-12">
        <v-img :src="src"></v-img>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'QRCodePanel',
  components: {},
  props: {
    src: String,
    firstName: String,
    lastName: String,
  },
};
</script>
