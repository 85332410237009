<!-- eslint-disable max-len -->
<template>
  <v-card class="pa-6">
    <p v-if="!viewQRCodes" class="text-h4">
      {{ $t('form.switchPanel.showQRCodesTitle') }}
    </p>
    <p v-if="viewQRCodes" class="text-h4">
      {{ $t('form.switchPanel.hideQRCodesTitle') }}
    </p>
    <v-row class="align-center">
      <v-col cols="3" v-if="!viewQRCodes">
        <v-btn @click="showQRCodes()">
          {{ $t('form.switchPanel.showQRCodes') }}
        </v-btn>
      </v-col>
      <v-col cols=" 3" v-if="viewQRCodes">
        <v-btn @click="hideQRCodes()" color=" error">
          {{ $t('form.switchPanel.hideQRCodes') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'SwitchPanel',
  components: {},
  props: {
    viewQRCodes: Boolean,
  },
  methods: {
    showQRCodes() {
      this.$emit('update-view-qr-codes', true);
    },
    hideQRCodes() {
      this.$emit('update-view-qr-codes', false);
    },
  },
};
</script>
