import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#003B71',
        secondary: '#1B8AAE',
        anchor: '#8c9eff',
      },
    },
  },
});

export default vuetify;
