<!-- eslint-disable max-len -->
<template>
  <v-card class="pa-6">
    <p class="text-h4">
      {{ $t('form.userInfoPanel.title') }}
    </p>
    <v-row>
      <v-col>
        <v-checkbox :label="$t('form.userInfoPanel.participation')" v-model="newIsNotComing">
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h6 class="subtitle-1">
          {{ $t('form.userInfoPanel.mail') }}
        </h6>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="4">
        <v-text-field label="Email" required :rules="emailRules" v-model="newPersonalMail"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-checkbox :label="$t('form.userInfoPanel.rpdg')" v-model="newNotAcceptAlumni">
        </v-checkbox>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'UserInfoPanel',
  components: {},
  props: {
    personalMail: String,
    isComing: Boolean,
    acceptAlumni: Boolean,
  },
  data() {
    return {
      valid: true,
      emailRules: [
        (v) => !!v || this.$i18n.t('errors.emailRequired'),
        (v) => /.+@.+/.test(v) || this.$i18n.t('errors.emailValid'),
      ],
      newPersonalMail: this.personalMail,
      newIsNotComing: !this.isComing,
      newNotAcceptAlumni: !this.acceptAlumni,
    };
  },
  computed: {},
  methods: {},
  watch: {
    newPersonalMail: {
      handler(newVal) {
        this.$emit('update-personnal-email', newVal);
      },
    },
    newIsNotComing: {
      handler(newVal) {
        this.$emit('update-is-coming', !newVal);
      },
    },
    newNotAcceptAlumni: {
      handler(newVal) {
        this.$emit('update-accept-alumni', !newVal);
      },
    },
  },
};
</script>
