<template>
  <v-container fluid class="fill-height pa-0">
    <v-row class="fill-height">
      <v-col lg="3" xl="2">
        <SidePanel>
        </SidePanel>
      </v-col>
      <v-col lg="9" xl="10" class="back-panel">
        <BackPanel>
          <h1 class="headline">
            Le formulaire d'Inscription à la Remise des Diplômes est
            actuellement fermé
          </h1>
        </BackPanel>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SidePanel from '../components/SidePanel.vue';
import BackPanel from '../components/BackPanel.vue';

export default {
  name: 'ClosePage',
  components: {
    SidePanel,
    BackPanel,
  },
};
</script>

<style>
.back-panel {
  background-color: #0a406b;
}

.white-text {
  color: white;
}
</style>
