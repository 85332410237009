<!-- eslint-disable max-len -->
<template>
  <v-card class="pa-6">
    <p class="text-h4">Nombre de photos</p>
    <v-form v-model="valid" lazy-validation>
      <v-row class="align-center">
        <v-col cols="4">
          <v-text-field label="Nombre de photos max par diplômé" v-model="picturesLimit" type="number" filled reverse
            :rules="numberRules" />
        </v-col>
        <v-col cols="3">
          <v-btn :disabled="!valid" @click="updateConfig()">Valider</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: 'PictureNumberPanel',
  components: {},
  props: {
    config: Object,
  },
  data() {
    return {
      valid: true,
      picturesLimit: this.config.picturesLimit,
      numberRules: [
        (v) => !!v || 'Une valeur est nécessaire',
        (v) => v >= 0 || 'La valeur doit être supérieure ou égale à 0',
      ],
    };
  },
  methods: {
    updateConfig() {
      this.$emit('update-config', { picturesLimit: this.picturesLimit });
    },
  },
};
</script>
