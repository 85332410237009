<!-- eslint-disable max-len -->
<template>
  <v-card class="pa-6">
    <p class="text-h4">
      {{ $t('form.savePanel.title') }}
    </p>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-row>
          <!-- <v-col cols="12" md="" align="center">
            <v-btn @click="reset()">
              {{ $t('form.savePanel.resetButton') }}
            </v-btn>
          </v-col> -->
          <v-col cols="12" md="" align="center">
            <v-btn @click="save()" color="primary">
              {{ $t('form.savePanel.saveButton') }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="" align="center">
            <v-btn @click="validate()" color="success" :disabled="!valid">
              {{ $t('form.savePanel.validateButton') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'SavePanel',
  props: {
    valid: Boolean,
  },
  methods: {
    reset() {
      this.$emit('reset');
    },
    save() {
      this.$emit('save');
    },
    validate() {
      this.$emit('validate');
    },
  },
};
</script>
