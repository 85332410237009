<template>
  <v-card class="pa-6">
    <p class="text-h4">
      {{ $t('form.guestAddPanel.title') }}
    </p>
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field label="Prénom" required v-model="firstName"></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field label="Nom" required v-model="lastName"></v-text-field>
      </v-col>
      <v-col cols="12" md="4" align="center">
        <v-btn @click="addGuest()" :disabled="disabled">
          {{ $t('form.guestAddPanel.addButton') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'GuestAddPanel',
  components: {},
  data() {
    return {
      firstName: '',
      lastName: '',
    };
  },
  methods: {
    addGuest() {
      this.$emit('add-guest', { firstName: this.firstName, lastName: this.lastName });
      this.firstName = '';
      this.lastName = '';
    },
  },
  computed: {
    disabled() {
      return !this.firstName || !this.lastName;
    },
  },
};
</script>
