<template>
  <v-card class="pa-6">
    <p class="text-h4">Statistiques</p>
    <v-row class="align-center">
      <v-col v-for="({ name, value }, index) in stats" :key="index" cols="12" md="">
        <NumberDisplay :name="name" :value="value" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import NumberDisplay from '../NumberDisplay.vue';

export default {
  name: 'StatsPanel',
  components: {
    NumberDisplay,
  },
  props: {
    stats: Array,
  },
};
</script>
