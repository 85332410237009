<template>
  <v-card class="pa-6">
    <p class="text-h2 mb-6">{{ $t('form.titleUser.title') }}</p>
    <p class="text-h5">{{ $t('form.titleUser.welcome') }} {{ firstName }} {{ lastName }}</p>
  </v-card>
</template>

<script>
export default {
  name: 'TitleUser',
  components: {},
  props: {
    firstName: String,
    lastName: String,
  },
};
</script>
